
import { defineComponent, PropType } from 'vue'
import { Instruction, IoBrief } from '../../../types/instruction_type'
import {
  calcTrend,
  round,
  stringTemplateYDA,
  TREND_ICON_COLOR
} from '@/components/SurcoucheV2/surcoucheV2utils'

type TypeConstraint = 'CPM' | 'Margin' | 'Viewability'
const SUPP_GAP_LIMIT = 10
const INF_GAP_LIMIT = -10
const PERCENT_OF_MAX = 0.9

export default defineComponent({
  name: 'ConstraintHealth',
  props: {
    io: {
      type: Object as PropType<IoBrief<Instruction>>
    }
  },
  data: function () {
    return {
      htmlConstraintBilling: '',
      htmlViewability: '',
      htmlMargin: '',
      trendConstraintIcon: '',
      trendConstraintPercent: '',
      constraintIconColor: '',
      trendMarginConstraintPercent: '',
      trendMarginConstraintIcon: '',
      marginIconColor: '',
      trendViewabilityConstraintIcon: '',
      trendViewabilityConstrainPercent: '',
      viewabilityIconColor: '',
      constraintIsBold: false,
      marginIsBold: false,
      viewAbilityIsBold: false,
      constraintLast7: null,
      constraintYesterday: null,
      objWatcher: null
    }
  },
  mounted () {
  },
  methods: {
    formatConstraintData (type: TypeConstraint, last7data: number | null, yesterdayData: number | null) {
      const trend = calcTrend(yesterdayData, last7data)
      if (last7data && yesterdayData) {
        this.setPercentValue(type, trend)
        this.showConstrainticon(yesterdayData, last7data, type)
      }

      let { minValue, maxValue } = this.setMinMaxValue(type)

      if (minValue === null && maxValue === null) {
        console.warn('Error with type value !')
        return
      }

      if (minValue <= last7data && last7data <= maxValue) {
        if (trend > SUPP_GAP_LIMIT) {
          this.setIconColor(type)
        }
        return `${type}: ${minValue} ≤ ${last7data} ≤ ${maxValue}`
      } else if (maxValue && last7data > maxValue) {
        if (trend > SUPP_GAP_LIMIT) {
          this.setIconColor(type)
        }
        if (PERCENT_OF_MAX * last7data > maxValue) {
          this.setBold(type)
        }
        return `${type}: ${last7data} ${maxValue ? '> ' + maxValue : ''}`
      } else if (minValue && last7data < minValue) {
        if (trend < INF_GAP_LIMIT) {
          this.setIconColor(type)
        }
        if (PERCENT_OF_MAX * last7data < minValue) {
          this.setBold(type)
        }
        return `${type}: ${last7data} ${minValue ? '< ' + minValue : ''}`
      } else {
        if (trend > SUPP_GAP_LIMIT) {
          this.setIconColor(type)
        }
        return `${type}: ${last7data} `
      }
    },
    getConstraints () {
      if (!this.constraintLast7 && !this.constraintYesterday) {
        return ''
      }
      this.getCPMConstraintBilling()
      this.getMarginConstraint()
      this.getViewabilityConstraint()
    },
    getCPMConstraintBilling () {
      const analyticsCPM: number | null = round(this.constraintLast7?.cpm)
      const analyticsCPMYesterday: number | null = round(this.constraintYesterday?.cpm)
      this.htmlConstraintBilling = this.formatConstraintData('CPM', analyticsCPM, analyticsCPMYesterday)
    },
    getMarginConstraint () {
      const constraintYesterdayMargin = this.constraintYesterday?.margin
      const constraintLast7Margin = this.constraintLast7?.margin
      this.htmlMargin = this.formatConstraintData('Margin', constraintLast7Margin, constraintYesterdayMargin)
    },
    getViewabilityConstraint () {
      const constraintYesterdayViewability = this.constraintYesterday?.viewability
      const constraintLast7Viewability = this.constraintLast7?.viewability
      this.htmlViewability = this.formatConstraintData('Viewability', constraintLast7Viewability, constraintYesterdayViewability)
    },
    showConstrainticon (yesterday: number, last7: number, type?: TypeConstraint) {
      const trendIcon = (yesterday - last7 > 0) ? 'trending-up' : 'trending-down'
      if (type === 'CPM') {
        this.trendConstraintIcon = trendIcon
      } else if (type === 'Margin') {
        this.trendMarginConstraintIcon = trendIcon
      } else if (type === 'Viewability') {
        this.trendViewabilityConstraintIcon = trendIcon
      }
    },
    setIconColor (type: TypeConstraint) {
      if (type === 'Margin') {
        this.marginIconColor = TREND_ICON_COLOR
      } else if (type === 'Viewability') {
        this.viewabilityIconColor = TREND_ICON_COLOR
      } else if (type === 'CPM') {
        this.constraintIconColor = TREND_ICON_COLOR
      }
    },
    setBold (type: TypeConstraint) {
      if (type === 'Margin') {
        this.marginIsBold = true
      } else if (type === 'Viewability') {
        this.viewAbilityIsBold = true
      } else if (type === 'CPM') {
        this.constraintIsBold = true
      }
    },
    setPercentValue (type: TypeConstraint, percent: number) {
      if (type === 'CPM') {
        this.trendConstraintPercent = stringTemplateYDA(percent)
      } else if (type === 'Viewability') {
        this.trendViewabilityConstrainPercent = stringTemplateYDA(percent)
      } else if (type === 'Margin') {
        this.trendMarginConstraintPercent = stringTemplateYDA(percent)
      }
    },
    setMinMaxValue (type: TypeConstraint) {
      let minValue = null
      let maxValue = null
      if (type === 'CPM') {
        minValue = round(this.objWatcher?.min_CPM?.value)
        maxValue = round(this.objWatcher?.max_CPM?.value)
      } else if (type === 'Viewability') {
        minValue = round(this.objWatcher?.min_viz?.value)
        maxValue = null
      } else if (type === 'Margin') {
        minValue = round(this.objWatcher?.margin?.value)
        maxValue = null
      }
      return { minValue, maxValue }
    }
  },
  watch: {
    io: {
      immediate: true,
      deep: true,
      handler: async function () {
        this.objWatcher = this.io.obj_watcher
        this.constraintLast7 = this.io?.analytics_kpi_interfaces?.find((elem) => elem.period === 'last_7_days')
        this.constraintYesterday = this.io?.analytics_kpi_interfaces?.find((elem) => elem.period === 'yesterday')
        this.getConstraints()
      }
    }
  }
})
