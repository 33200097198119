import { defineComponent, PropType } from 'vue'
import { InsertionOrderProcessor } from '../../utils/Processor/InsertionOrderProcessor'
import { Baseline, CollectionInstruction, Instruction, InstructionDsp, IoBrief } from '../../types/instruction_type'
import { $BEESWAX } from '../../config/dspConfig'
import { EDIT } from '../../store'
import { briefFieldsConfigMixin } from '@/mixins/briefFieldConfig'

export type PreparedInstructionToSave = {
  editedInstru: Instruction[],
  newInstru: Instruction[],
  toDeleteInstruId: number[]
}

export default defineComponent({
  props: {
    dsp: {
      type: String as PropType<InstructionDsp>,
      required: true
    }
  },
  mixins: [ briefFieldsConfigMixin ],
  data: function () {
    return {
      editedInstructionsIO: [],
      editedItem: {} as any,
      dialog: false,
      dataFormGroupKey: {},
      insertionOrderIdStrat: null,
      instructionIdStrat: null,
      dialogInstruStrat: false,
      editedIndex: -1,
      editedIO: -1,
      ioList: [],
      instructions: null as CollectionInstruction<Instruction>,
      insertionOrderProcessor: new InsertionOrderProcessor()
    }
  },
  methods: {
    resetDataFormValues () {
      this.dataFormGroupKey = {}
    },
    formIsEdit () {
      return this.$store.getters.getFormStatus === EDIT
    },
    setEditedItemToDefaultValue () {
      let defaultItem = this.$store.getters.getDefaultItem
      this.editedItem = this.$commonUtils.deepCopy(defaultItem)
    },
    /**
     * @param insertionOrderId {number}
     * @param instructionId {number}
     */
    openInstruStratDialog (insertionOrderId: number, instructionId: number = null) {
      this.insertionOrderIdStrat = insertionOrderId
      this.dialogInstruStrat = true
      this.instructionIdStrat = instructionId
    },
    async setupEditedInstructionIO (key: number) {
      let ioBrief = this.editedItem
      if (this.dsp === $BEESWAX) {
        ioBrief = this.$commonUtils.deepCopy(this.editedItem)
        ioBrief.io = ioBrief.io.split('_')[1]
      }

      const briefConfig = await this.getBriefFieldsForDsp(this.dsp)
      const toMerge = briefConfig.to_merge
      this.editedInstructionsIO[key] = this.insertionOrderProcessor.fillInstructionsWithIOBrief(
        this.editedInstructionsIO[key],
        ioBrief,
        this.$dspConfig[this.dsp],
        toMerge
      )
    },
    async createMultiProcess (acknowledgments: AcknowledgmentInfo[] = null, baseline: Baseline = null) {
      this.$commonUtils.removeEmptyFieldFromObject(this.editedItem)

      for (let i in this.editedInstructionsIO) {
        if (!this.editedInstructionsIO.hasOwnProperty(i)) {
          continue
        }

        this.$commonUtils.removeEmptyFieldFromObject(this.editedInstructionsIO[i])

        await this.setupEditedInstructionIO(Number(i))

        if (this.editedInstructionsIO[i].id) {
          delete this.editedInstructionsIO[i].id
        }
      }
      this.$emit('delete-and-post-raw', this.editedInstructionsIO, acknowledgments, null, baseline)
    },
    async prepareEditMultiProcess (): Promise<PreparedInstructionToSave> {
      this.$commonUtils.removeNullAndUndefinedValueFromObject(this.editedItem)
      this.$set(this.ioList, this.editedIndex, this.$commonUtils.deepCopy(this.editedItem))
      // will contain the new instruction who need to be created and not edited
      let newInstru = []
      // will contain the instruction id who need to be deleted
      let toDeleteInstruId: Array<number> = []

      let instructionIdList = this.editedInstructionsIO.map((instruction) => {
        return instruction.id
      })

      let ioIdList = this.editedItem.id_list

      for (let i in ioIdList) {
        // if the id isn't in the instructionIdList, that means it has to be deleted
        if (instructionIdList.indexOf(ioIdList[i]) === -1) {
          toDeleteInstruId.push(ioIdList[i])
        }
      }

      for (let i in this.editedInstructionsIO) {
        if (!this.editedInstructionsIO.hasOwnProperty(i)) {
          continue
        }
        let isNewInstruction = false
        // if empty id, the instruction is a new instruction
        if (this.editedInstructionsIO[i].id === '') {
          isNewInstruction = true
          // we use formKey to identify the instruction in the v-for loop InstructionContainer.
          // it's a trick to avoid the v-for loop to be re-rendered when the instruction is updated
          // we need to delete it before sending the instruction to the api.
          if ('formKey' in this.editedInstructionsIO[i]) {
            delete this.editedInstructionsIO[i].formKey
          }
        }
        this.$commonUtils.removeNullAndUndefinedValueFromObject(this.editedInstructionsIO[i])

        await this.setupEditedInstructionIO(Number(i))
        // here we take the id cause the io can be updated and the id not
        let instruIO = this.$commonUtils.deepCopy(this.instructions[this.editedItem.id])
        instruIO[i] = this.$commonUtils.deepCopy(this.editedInstructionsIO[i])
        this.$set(this.instructions, this.editedItem.io, this.$commonUtils.deepCopy(instruIO))

        // if new instruction, we don't emit it with this.editedInstructionsIO
        if (isNewInstruction) {
          delete this.editedInstructionsIO[i].id
          this.$commonUtils.removeEmptyFieldFromObject(this.editedInstructionsIO[i])
          newInstru.push(this.$commonUtils.deepCopy(this.editedInstructionsIO[i]))
          delete this.editedInstructionsIO[i]
        }
      }

      // if the id and the io are different, io has been updated and the line need to be removed
      // the id is the old io
      if (this.editedItem.io !== this.editedItem.id) {
        console.log('IO has been updated')
        this.$delete(this.instructions, this.editedItem.id)
      }

      // delete line
      if (toDeleteInstruId.length > 0) {
        for (let i in toDeleteInstruId) {
          // remove the deleted id from the insertion_order id_list
          let ioList = this.instructions[this.editedItem.id]
          this.instructions[this.editedItem.id] = ioList.filter((item) => item.id !== toDeleteInstruId[i])
          // TODO check if it's still relevant
          this.$commonUtils.removeElementFromDom(toDeleteInstruId[i])
        }
      }
      // some instru can be undefined if deleted previously
      const editedInstru = this.editedInstructionsIO.filter(item => item !== undefined)
      return {
        editedInstru: editedInstru,
        newInstru: newInstru,
        toDeleteInstruId: toDeleteInstruId
      }
    },
    /**
     * called when the IOForm is closed
     */
    close () {
      this.dialog = false
      this.resetDataFormValues()
      setTimeout(() => {
        // SPECIAL TRICKS
        // edited item need to return to her default value when the form is closed
        // but this function is always called when the components is created
        // and this behavior disallow the query.formItem to be right registered
        // in the IOForm when necessary
        // To avoid this behavior, check in $route.query is formItem is defined
        if (this.$route.query.formItem === undefined) {
          this.setEditedItemToDefaultValue()
          this.editedInstructionsIO = []
        }
        this.editedIndex = -1
        this.editedIO = -1
      }, 300)
    }
  }
})
