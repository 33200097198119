
import { defineComponent, PropType } from 'vue'
import { Instruction, IoBrief } from '../../../types/instruction_type'
import { AnalyticsKpiInterface } from '../../../types/overview_types'
import { calcTrend, stringTemplateYDA, TREND_ICON_COLOR } from '@/components/SurcoucheV2/surcoucheV2utils'

const PERCENT_OF_MAX = 0.9

export default defineComponent({
  name: 'KpiLastWeekHealth',
  props: {
    io: {
      type: Object as PropType<IoBrief<Instruction>>
    }
  },
  data: function () {
    return {
      hoverKpiBilling: null,
      trendingPercent: null,
      trendIcon: '',
      percent: null,
      isBold: false,
      trendIconColor: '',
      isOverTenPercent: true
    }
  },
  methods: {
    round (value: number, precision = 2) {
      if (!value) {
        return value
      }
      return this.$commonUtils.roundNum(value, precision)
    },
    getKpiBilling () {
      if (!this.io.analytics_kpi_interfaces || this.io.analytics_kpi_interfaces.length === 0) {
        this.setHoverKpiBilling(null)
        return ''
      }
      const kpiInterface = this.io.analytics_kpi_interfaces
      const kpiLast7: AnalyticsKpiInterface = kpiInterface.find((elem) => elem.period === 'last_7_days')
      const yesterday: AnalyticsKpiInterface = kpiInterface.find((elem) => elem.period === 'yesterday')
      const target: number = this.round(this.io.obj_watcher.KPI_to_optimize.value) ?? null
      let trend: string = '<'
      if (target) {
        this.percent = calcTrend(yesterday.kpi_to_optimize_value, kpiLast7.kpi_to_optimize_value)
        this.trendingPercent = stringTemplateYDA(this.percent)
        if (target - kpiLast7.kpi_to_optimize_value > 0) {
          trend = '<'
        } else {
          trend = '>'
        }
        if (this.percent > 0) {
          this.trendIcon = 'trending-up'
        } else if (this.percent < 0) {
          this.trendIcon = 'trending-down'
        }
        this.setKpiBillingStyles(kpiLast7, target)
        this.setHoverKpiBilling(kpiLast7)
        return `${this.io.KPI_to_optimize}: ${this.round(kpiLast7.kpi_to_optimize_value)} ${trend} ${target}`
      } else {
        this.setHoverKpiBilling(null)
        return ''
      }
    },
    setHoverKpiBilling (kpiLast7: AnalyticsKpiInterface) {
      if (kpiLast7 !== null) {
        const startDate: string = new Date(kpiLast7.start_period_tz).toDateString()
        const endDate: string = new Date(kpiLast7.end_period_tz).toDateString()
        this.hoverKpiBilling = `Last 7 days: from ${startDate} to ${endDate} (IO tz)`
      } else {
        this.hoverKpiBilling = null
      }
    },
    setKpiBillingStyles (kpiLast7: AnalyticsKpiInterface, target: number) {
      if (this.io.obj_watcher.KPI_to_optimize.KPI in ['CTR', 'VTR']) {
        this.isBold = (PERCENT_OF_MAX * kpiLast7.kpi_to_optimize_value) < target
        this.trendIconColor = this.percent < -10 ? TREND_ICON_COLOR : ''
        this.isOverTenPercent = this.percent < -10
      } else {
        this.isBold = (PERCENT_OF_MAX * kpiLast7.kpi_to_optimize_value) > target
        this.trendIconColor = this.percent > 10 ? TREND_ICON_COLOR : ''
        this.isOverTenPercent = this.percent > 10
      }
    }
  }
})
