
import { defineComponent, PropType } from 'vue'
import { instructionsCaller } from '@/mixins/instructionsCaller'
import {
  Baseline,
  DspInstruction, GroupKeysArgs,
  Instruction,
  IoBrief,
  SearchItem
} from '../../../types/instruction_type'
import IOForm from '@/components/TableComponents/Form/IOForm.vue'
import { mapMutations } from 'vuex'
import IoFormMixin from '@/mixins/ioFormMixin'
import OutcomesDialog from '@/components/TableComponents/Outcomes/OutcomesDialog.vue'
import { getIoField } from '../../../utils/instructionsUtils'
import LineIOV2 from '@/components/SurcoucheV2/LineIOV2.vue'
import {
  IState,
  SurcoucheV2Filters
} from '../../../types/surcouche_v2_types'
import { getCurrentSearchFromQuery } from '@/components/SurcoucheV2/surcoucheV2utils'
import '@/styles/surcoucheV2.styles.css'
import GroupKeyModel from '@/models/Alertings/GroupKeyModel'
import { Headers } from '../../../config/dspConfig'
import { briefFieldsConfigMixin } from '@/mixins/briefFieldConfig'

export default defineComponent({
  name: 'InsertionOrderView',
  components: {
    LineIOV2,
    OutcomesDialog,
    IOForm
  },
  mixins: [ instructionsCaller, IoFormMixin, briefFieldsConfigMixin ],
  props: {
    searchValue: {
      type: String,
      required: false,
      default: () => {
        return ''
      }
    },
    filters: {
      type: Object as PropType<SurcoucheV2Filters>,
      required: true
    },
    filterHeight: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      header: [
        { text: 'Name', value: 'name' },
        { text: 'Health', value: 'health' },
        { text: 'Alerts', value: 'alerts' }
      ],
      checkBoxSelected: false,
      isHover: false,
      singleSelect: true,
      ioList: [],
      searchBufferize: '' as string | string[],
      entityType: 'company',
      expanded: [],
      selected: {},
      arrayPagination: [20, 30, 50],
      listInstru: null,
      isEditForm: true,
      editedIOIndex: -1,
      data: null,
      alerts: {}, // rename
      groupKeyModelList: [] as GroupKeyModel[]
    }
  },
  mounted: async function () {
    const filtersQuery = getCurrentSearchFromQuery(this.$route.query)

    this.data = await this.fetchNextIo(filtersQuery)

    this.instructions = this.data
    try {
      const briefConfig = await this.getBriefFieldsForDsp(this.dsp)
      const toMerge = briefConfig.to_merge
      this.ioList = this.insertionOrderProcessor.mapListIO(
        this.instructions,
        this.$dspConfig[this.dsp],
        toMerge
      )
    } catch (TypeError) {
      console.warn('TypeError mapListIO')
    }

    const insertionOrderIds = Object.keys(this.data)
    const groupKeysResponse = await this.$apiCaller.getGroupKeysWithInsertionOrderId(insertionOrderIds)
    this.addAlertToIoList(groupKeysResponse.data)
  },
  methods: {
    ...mapMutations(['setErrorMessage', 'editStartUpdate', 'editEndUpdate']),
    async refreshData () {
      const filters = getCurrentSearchFromQuery(this.filters)
      this.data = await this.fetchNextIo(filters)
    },
    selectRowsInstructions (ids: string[] = [], state: IState) {
      for (let key in ids) {
        this.$set(this.selected, ids[key], state)
      }
    },
    selectRowsKpi (id: string, instruction: Instruction, state: IState) {
      this.$set(this.selectedIos, id, { instruction, state })
    },
    onUpdatePagination () {
      this.refreshData()
    },
    /**
     * process called when form is in edit
     * emit a event 'delete-and-post-raw'
     * @param acknowledgments {AcknowledgmentInfo[]}
     * @param baseline {Baseline}
     */
    async editMultiProcess (acknowledgments: AcknowledgmentInfo[] = null, baseline: Baseline = null) {
      const result = await this.prepareEditMultiProcess()
      this.deleteAndPostRaw([
        ...result.editedInstru,
        ...result.newInstru
      ], acknowledgments, result.toDeleteInstruId, baseline)
    },
    /**
     * called when user click on the save button of the IOForm
     * emit the appropriate call request to the parent element
     * in function of the call action
     */
    async save (acknowledgments: AcknowledgmentInfo[] = null, baseline: Baseline = null) {
      if (this.formIsEdit()) {
        // for editing
        await this.editMultiProcess(acknowledgments, baseline)
      } else {
        // for new item
        await this.createMultiProcess(acknowledgments, baseline)
      }
      this.close()
    }, // end method update box
    getIOField () {
      return getIoField(this.dsp)
    },
    /**
     * Call the api for the io,
     * update the instruction in the collection
     * and open the IoForm
     * @param item {import('../../../types/instruction_type').IoBrief}
     */
    async editItem (item: IoBrief<DspInstruction>) {
      // 1. call api for each id
      this.editStartUpdate()
      this.editedIOIndex = Number(item.io)
      let updatedInstruction : Instruction[] = []

      const response = await this.$apiCaller.getSurcoucheV2InsertionOrder(this.dsp, item.io)

      if (this.$apiCaller.isResponseError(response)) {
        this.$store.commit('setErrorMessageWithResponse', response)
        this.editEndUpdate()
        return
      }

      updatedInstruction = response.data

      // replace instruction per her updated version
      // TODO need better solution (bad practice to call parent data). When call in instruction mixin will be included in ScibidsApicaller
      // TODO this value will be in the store
      // 2. replace the instruction per the instruction received
      this.$set(this.instructionSortedByIo, this.editedIOIndex, updatedInstruction)

      // 3. open the form
      this.$nextTick(() => {
        // get updated insertion_order
        let updatedItem = this.ioList.filter((io) => {
          return io.io === item.io
        })
        // update id_list
        updatedItem[0].id_list = updatedInstruction.map(item => item.id)
        const ioBrief = updatedItem[0]
        this.openEditForm(ioBrief, updatedInstruction)
        this.editEndUpdate()
      })
    },
    /**
     *
     * @param ioBrief {import('../../../types/instruction_type').IoBrief}
     * @param updatedInstructions {Instruction[]}
     */
    openEditForm (ioBrief: IoBrief<Instruction>, updatedInstructions: Instruction[]) {
      this.$authModule.checkIfRefreshIsNeeded()
      this.$store.commit('setFormStatus', this.$EDIT)
      this.editedInstructionsIO = this.$commonUtils.deepCopy(updatedInstructions)
      this.editedItem = this.$commonUtils.deepCopy(ioBrief)
      this.isEditForm = true
      this.dialog = true
    },
    formIsNew () {
      return this.$store.getters.getFormStatus === this.$NEW
    },
    generateAlertModel (identityList: GroupKeysArgs[]) {
      for (let identity of identityList) {
        const groupModel = new GroupKeyModel(identity)
        this.groupKeyModelList.push(groupModel)
      }
    },
    addAlertToIoList (alerts: GroupKeysArgs[]) {
      this.alerts = alerts.reduce((acc: {[key: string] : string[]}, curr) => {
        const key = curr.identity.insertion_order_id
        acc[key] = curr.error_type
        return acc
      }, {})
    },
    async getGroupKeys (insertionOrderIds: string[]) {
      const res = await this.$apiCaller.getGroupKeysWithInsertionOrderId(insertionOrderIds)
      this.addAlertToIoList(res.data)
      this.generateAlertModel(res.data)
    }
  },
  computed: {
    getIOHeader () : Headers {
      return this.$dspConfig.IOHeader.headersV2
    },
    totalItems () : number {
      if (!this.loading && this.ioList.length < this.options.itemsPerPage) {
        return this.displayedPageStop
      }
      return (this.options.itemsPerPage * (this.options.page + 1)) + 1
    },
    displayedPageStop () : number {
      const startPage = this.options.itemsPerPage * (this.options.page - 1)
      return this.ioList.length < this.options.itemsPerPage
        ? startPage + this.ioList.length
        : startPage + this.options.itemsPerPage
    },
    options: {
      get () : { itemsPerPage: number, page: number } {
        return this.$store.getters.getOptionsInstructions
      },
      set (options : { itemsPerPage: number, page: number }) {
        this.$store.commit('setOptionsInstructions', options)
      }
    },
    formTitle () {
      return this.getFormStatus + ' item'
    },
    getFormStatus () : string {
      return this.$store.getters.getFormStatus
    },
    keyIoForm () : string {
      return this.formIsNew() ? `NEW_FORM` : this.editedItem.id
    },
    currentSearch: {
      get (): SearchItem {
        return this.$store.getters.getCurrentSearch
      },
      set (currentSearch: SearchItem) {
        this.$store.commit('setCurrentSearch', currentSearch)
      }
    },
    loading (): boolean {
      return this.$store.getters.getSteamingProcessInProgress
    }
  },
  watch: {
    'dialog': {
      immediate: false,
      handler: function (dialog, oldValue) {
        let path
        if (dialog) {
          path = this.$router.resolve({
            params: {
              dsp: this.dsp,
              openDialog: 'ioForm',
              dialogId: this.editedItem.io
            },
            query: this.$route.query
          })
          this.$store.commit('setAppState', this.$FORM_IS_OPEN)

          // In case the user come from the edit url, and the instructions are not in the IoList, the user will be unable
          // to save. For avoid that, we detect when this io is not the instructions collections.
          // If not, we just search the io in searchBufferize
          if (!(this.editedItem.io in this.instructions)) {
            this.searchBufferize = this.editedItem.io
          }
        } else {
          path = this.$router.resolve({
            params: {
              dsp: this.dsp,
              openDialog: null,
              dialogId: null
            },
            query: this.$route.query
          })
          this.$store.commit('setAppStateToNormal')
        }
        // check for avoid NavigationDuplicated error
        if (path && path.href !== this.$route.fullPath) {
          this.$router.replace(path.location)
          this.$plausibleHelper.trackPageview()
        }
      }
    },
    '$store.getters.getOpenDataForm': {
      immediate: true,
      handler: function (openDataForm) {
        if (openDataForm) {
          console.warn('Form opened with data_form')
          this.dataFormGroupKey = this.$store.getters.getDataFormGroupKey
        }
      }
    },
    data: {
      deep: true,
      immediate: false,
      handler: async function () {
        this.instructions = this.data
        try {
          const briefConfig = await this.getBriefFieldsForDsp(this.dsp)
          const toMerge = briefConfig.to_merge
          this.ioList = this.insertionOrderProcessor.mapListIO(
            this.instructions,
            this.$dspConfig[this.dsp],
            toMerge
          )
        } catch (TypeError) {
          console.warn('TypeError mapListIO', TypeError)
        }
        await this.getGroupKeys(Object.keys(this.data))
      }
    },
    filters: {
      deep: true,
      handler: async function () {
        const query = this.$route.query
        const filters = getCurrentSearchFromQuery(query)
        this.data = await this.fetchNextIo(filters)
      }
    },
    '$store.getters.getSteamingProcessInProgress': {
      deep: true,
      immediate: true,
      handler: async function (value) {
        console.log('streaming process ', value)
      }
    }
  }
})
